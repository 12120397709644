<script>
  import { _ } from "svelte-i18n";
  import { startup_state, keywords_store, loading_keywords, is_tag_array } from "$lib/store.js";
  import { onMount } from "svelte";

  export let keyword;
  export let showIcon = false;
  export let showChevron = false;
  export let showCategoryName = false;
  export let selectableCategories = false;

  // let preview;
  let keywordUnit;
  let mouseX;
  let keywordState = "";
  let dropdownOpen = false;
  let dropdownPosition;
  let keywordDropdown;
  let expandCategories;

  $: if (keywordDropdown) {
    setDropdownPosition();
  }

  keywords_store.subscribe(() => {
    // reactively update keyword.incl_excl
    keyword.incl_excl =
      ($keywords_store.find((item) => item.displayText === keyword.displayText) || {}).incl_excl ?? "";
    setKeywordState();
  });

  function setKeyword(keyword) {
    if ($startup_state) {
      startup_state.set(false);
    }
    if ($keywords_store.find((item) => item.displayText === keyword.displayText)) {
      flipKeyword(keyword);
    } else addKeyword(keyword);
  }

  function flipKeyword(keyword) {
    keywords_store.update((existing_keywords) => {
      return existing_keywords.map((k) => {
        if (k.displayText === keyword.displayText) {
          k.incl_excl = keyword.incl_excl;
          k.category = keyword.category;
        }
        return k;
      });
    });
  }

  function addKeyword(keyword) {
    startup_state.set(false);
    $loading_keywords = true;
    const new_keyword = [
      {
        category: keyword.category,
        displayText: keyword.displayText,
        id: keyword.id,
        incl_excl: keyword.incl_excl,
        type: keyword.type
      }
    ];
    keywords_store.update((keywords) => {
      const combinedKeywords = [...keywords, ...new_keyword];
      const keywordsMap = new Map(combinedKeywords.map((keyword) => [keyword.displayText, keyword]));
      return Array.from(keywordsMap.values());
    });
    window._paq.push([
      "trackEvent",
      `${keyword.category}-keyword`,
      "added",
      `${keyword.displayText}; keyword-id: ${keyword.id};`
    ]);
    $loading_keywords = false;
  }

  function deleteKeyword(keyword) {
    keywords_store.update((keywords_old) => {
      return keywords_old.filter((k) => k.displayText !== keyword.displayText);
    });
  }

  //--# appearance

  function setKeywordState() {
    if (keyword.incl_excl === "INCLUDE") {
      // Update keyword Classes store reactively
      if (!$startup_state) {
        keywordState = "included";
      } else {
        keywordState = "inactive included";
      }
    } else if (keyword.incl_excl === "EXCLUDE") {
      if (!$startup_state) {
        keywordState = "excluded";
      } else {
        keywordState = "inactive excluded";
      }
    } else {
      keywordState = "";
    }
  }

  function toggleDropdown(event) {
    if ($startup_state && keyword.incl_excl != "") {
      return;
    } else {
      dropdownOpen = !dropdownOpen;
      mouseX = event.clientX;

      if (dropdownOpen) {
        // Add document click listener when dropdown opens to close dropdown on click outside
        setTimeout(() => {
          document.addEventListener("pointerdown", closeDropdown);
          document.addEventListener("scroll", closeDropdown);
        }, 0);
      } else {
        document.removeEventListener("pointerdown", closeDropdown);
        document.removeEventListener("scroll", closeDropdown);
      }
    }
  }

  function closeDropdown() {
    if (!dropdownOpen) {
      return;
    } else {
      dropdownOpen = false;
      document.removeEventListener("pointerdown", closeDropdown);
      document.removeEventListener("scroll", closeDropdown);
    }
  }

  function setDropdownPosition() {
    const keywordDropdownRect = keywordDropdown.getBoundingClientRect();
    const keywordUnitRect = keywordDropdown.parentNode.getBoundingClientRect();
    let left;
    let top;
    left = keywordUnitRect.left;
    top = keywordUnitRect.bottom;
    if (keywordUnitRect.bottom + keywordDropdownRect.height + 4 > window.innerHeight) {
      top = window.innerHeight - keywordDropdownRect.height - 18;
      if (mouseX + 4 + keywordDropdownRect.width > window.innerWidth) {
        left = window.innerWidth - keywordDropdownRect.width - 4;
      } else {
        left = mouseX + 4;
      }
    }
    dropdownPosition = `left: ${left}px; top: ${top}px;`;
    //   return `left: ${left}px; top: ${top}px;`;
  }

  function getIconName(categoryName) {
    switch (true) {
      case categoryName.includes("style"):
        return "style";
      case categoryName.includes("audience"):
        return "audience";
      case categoryName.includes("plot"):
        return "plot";
      case categoryName.includes("mood"):
        return "mood";
      case categoryName.includes("genre"):
        return "genre";
      case categoryName.includes("title"):
        return "title";
      case categoryName.includes("reception"):
        return "reception";
      case categoryName.includes("theme"):
        return "theme";
      case categoryName.includes("cast"):
        return "cast&crew";
      case categoryName.includes("production"):
        return "production-country";
      case categoryName.includes("year"):
        return "release-year";
      default:
        return "other";
    }
  }

  onMount(() => {
    // const params = new URLSearchParams(window.location.search);
    // preview = params.get("preview") === "true";
  });
</script>

<!-- ---------------------------------------------------------------------- -->

<div class="keyword-unit {$startup_state && $is_tag_array}" id={keyword.displayText} bind:this={keywordUnit}>
  <div
    class="keyword {keywordState}"
    role="button"
    appearance="none;"
    tabindex="0"
    aria-expanded={dropdownOpen}
    aria-haspopup="true"
    on:pointerdown={toggleDropdown}
    on:keypress={toggleDropdown}>
    {#if showChevron}
      {#if $startup_state && keyword.incl_excl == ""}
        <div class="chevron {dropdownOpen ? 'open' : ''}" />
      {:else if !$startup_state}
        <div class="chevron {dropdownOpen ? 'open' : ''}" />
      {/if}
    {/if}
    {#if showIcon}
      <div
        class="category-icon"
        style="background-image: url('/icons/icon_{getIconName(keyword.category.toLowerCase())}.svg" />
    {/if}

    {#if showCategoryName}
      {$_(keyword.category) + ": "}{/if}
    {keyword.displayText}
  </div>
  {#if dropdownOpen}
    <div
      class="dropdown"
      bind:this={keywordDropdown}
      style={dropdownPosition}
      on:pointerdown={(event) => {
        event.stopPropagation();
      }}>
      <div
        class="dropdown-item {keyword.incl_excl != 'INCLUDE' ? 'plus ' : ''}{keywordState == 'included'
          ? keywordState
          : ''}"
        role="button"
        tabindex="0"
        on:click={() => {
          keyword.incl_excl = "INCLUDE";
          closeDropdown();
          setKeyword(keyword);
        }}
        on:keydown={() => {
          closeDropdown();
        }}>
        {keywordState == "included" ? $_("included") : $_("include")}
      </div>
      <div
        class="dropdown-item {keyword.incl_excl != 'EXCLUDE' ? 'minus ' : ''}{keywordState == 'excluded'
          ? keywordState
          : ''}"
        role="button"
        tabindex="0"
        on:click={() => {
          keyword.incl_excl = "EXCLUDE";
          closeDropdown();
          setKeyword(keyword);
        }}
        on:keydown={() => {
          closeDropdown();
        }}>
        {keywordState == "excluded" ? $_("excluded") : $_("exclude")}
      </div>
      {#if selectableCategories}
        <div class="separator" />
        <div
          class="dropdown-item {keyword.category === 'mood' ? 'active ' : ''}{keyword.incl_excl.toLowerCase()}"
          role="button"
          tabindex="0"
          on:click={() => {
            keyword.category = "mood";
            closeDropdown();
            setKeyword(keyword);
          }}
          on:keydown={() => {
            closeDropdown();
          }}>
          <img src="/icons/icon_mood.svg" alt="mood" />
          {$_("Mood")}
        </div>
        <div
          class="dropdown-item {keyword.category === 'plot' ? 'active ' : ''}{keyword.incl_excl.toLowerCase()}"
          role="button"
          tabindex="0"
          on:click={() => {
            keyword.category = "plot";
            closeDropdown();
            setKeyword(keyword);
          }}
          on:keydown={() => {
            closeDropdown();
          }}>
          <img src="/icons/icon_plot.svg" alt="plot" />
          {$_("Plot")}
        </div>
        <div
          class="dropdown-item {getIconName(keyword.category) === 'style'
            ? 'active '
            : ''}{keyword.incl_excl.toLowerCase()}"
          role="button"
          tabindex="0"
          on:click={() => {
            keyword.category = "style";
            closeDropdown();
            setKeyword(keyword);
          }}
          on:keydown={() => {
            closeDropdown();
          }}>
          <img src="/icons/icon_style.svg" alt="style" />
          {$_("Style")}
        </div>
        <div
          class="dropdown-item {keyword.category.includes('genre') ? 'active ' : ''}{keyword.incl_excl.toLowerCase()}"
          role="button"
          tabindex="0"
          on:click={() => {
            keyword.category = "genre";
            closeDropdown();
            setKeyword(keyword);
          }}
          on:keydown={() => {
            closeDropdown();
          }}>
          <img src="/icons/icon_genre.svg" alt="genre" />
          {$_("Genre")}
        </div>
        <div
          class="dropdown-item {keyword.category === 'title' ? 'active ' : ''}{keyword.incl_excl.toLowerCase()}"
          role="button"
          tabindex="0"
          on:click={() => {
            keyword.category = "title";
            closeDropdown();
            setKeyword(keyword);
          }}
          on:keydown={() => {
            closeDropdown();
          }}>
          <img src="/icons/icon_title.svg" alt="title" />
          {$_("Title")}
        </div>
        <div
          class="dropdown-item {keyword.category === 'cast&crew' ? 'active ' : ''}{keyword.incl_excl.toLowerCase()}"
          role="button"
          tabindex="0"
          on:click={() => {
            keyword.category = "cast&crew";
            closeDropdown();
            setKeyword(keyword);
          }}
          on:keydown={() => {
            closeDropdown();
          }}>
          <img src="/icons/icon_cast&crew.svg" alt="castcrew" />
          {$_("Cast&Crew")}
        </div>
        {#if expandCategories || keyword.category == "theme"}
          <div
            class="dropdown-item {keyword.category === 'theme' ? 'active ' : ''}{keyword.incl_excl.toLowerCase()}"
            role="button"
            tabindex="0"
            on:click={() => {
              keyword.category = "theme";
              closeDropdown();
              setKeyword(keyword);
            }}
            on:keydown={() => {
              closeDropdown();
            }}>
            <img src="/icons/icon_theme.svg" alt="theme" />
            {$_("Theme")}
          </div>
        {/if}
        {#if expandCategories || keyword.category == "audience"}
          <div
            class="dropdown-item {keyword.category === 'audience' ? 'active ' : ''}{keyword.incl_excl.toLowerCase()}"
            role="button"
            tabindex="0"
            on:click={() => {
              keyword.category = "audience";
              closeDropdown();
              setKeyword(keyword);
            }}
            on:keydown={() => {
              closeDropdown();
            }}>
            <img src="/icons/icon_audience.svg" alt="audience" />
            {$_("Audience")}
          </div>
        {/if}
        {#if expandCategories || keyword.category == "reception"}
          <div
            class="dropdown-item {keyword.category === 'reception' ? 'active ' : ''}{keyword.incl_excl.toLowerCase()}"
            role="button"
            tabindex="0"
            on:click={() => {
              keyword.category = "reception";
              closeDropdown();
              setKeyword(keyword);
            }}
            on:keydown={() => {
              closeDropdown();
            }}>
            <img src="/icons/icon_reception.svg" alt="reception" />
            {$_("Reception")}
          </div>
        {/if}
        {#if expandCategories || keyword.category == "production-country"}
          <div
            class="dropdown-item {keyword.category === 'production-country'
              ? 'active '
              : ''}{keyword.incl_excl.toLowerCase()}"
            role="button"
            tabindex="0"
            on:click={() => {
              keyword.category = "production-country";
              closeDropdown();
              setKeyword(keyword);
            }}
            on:keydown={() => {
              closeDropdown();
            }}>
            <img src="/icons/icon_production-country.svg" alt="production-country" />
            {$_("Production-Country")}
          </div>
        {/if}
        {#if expandCategories || keyword.category == "release-year"}
          <div
            class="dropdown-item {keyword.category === 'release-year'
              ? 'active '
              : ''}{keyword.incl_excl.toLowerCase()}"
            role="button"
            tabindex="0"
            on:click={() => {
              keyword.category = "release-year";
              closeDropdown();
              setKeyword(keyword);
            }}
            on:keydown={() => {
              closeDropdown();
            }}>
            <img src="/icons/icon_release-year.svg" alt="release-year" />
            {$_("Release-Year")}
          </div>
        {/if}
        <button class="expanding-button" on:click={() => (expandCategories = !expandCategories)}>
          <div class={expandCategories == true ? "collapse" : "expand"} />
        </button>
      {/if}
    </div>
  {/if}
  {#if !$startup_state == true && keyword.incl_excl !== ""}
    <span
      class="delete-keyword"
      role="button"
      tabindex="0"
      on:click={() => deleteKeyword(keyword)}
      on:keypress={() => deleteKeyword(keyword)} />
  {/if}
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .keyword-unit {
    display: flex;
    /* gap: 2px; */
    margin: 2px;
    position: relative;
    height: max-content;
    -webkit-animation-fill-mode: both !important;
    animation-fill-mode: both !important;
  }
  .keyword {
    display: flex;
    align-items: center;
    cursor: pointer;
    background: var(--white-90);
    color: var(--solid-grey);
    border: 1px solid transparent;
    padding: 0.5rem 1rem;
    /* margin: 2px 3  px; */
    border-radius: 0.5rem;
    font-size: 1rem;
    text-align: left;
    word-wrap: break-word;
    hyphens: auto;
    line-height: 1.2;
    /* white-space: nowrap; */
  }
  .keyword:hover {
    cursor: pointer;
    background-color: var(--white);
    color: var(--dark-grey);
    border: 1px solid var(--dark-grey);
  }
  .keyword.included {
    color: var(--white);
    background-color: var(--solid-blue);
  }
  .keyword.included:hover {
    border: 1px solid var(--white);
  }
  .keyword.excluded {
    color: var(--white);
    background-color: var(--bright-red);
    text-decoration: line-through;
  }
  .keyword.excluded:hover {
    border: 1px solid var(--white);
  }
  .keyword.inactive {
    opacity: 0.6;
    cursor: auto;
  }
  .keyword.inactive:hover {
    border: 1px solid transparent;
  }
  .expanding-button {
    display: flex;
    width: 36px;
    height: 36px;
    border: solid 1px var(--solid-grey);
    border-radius: 18px;
    cursor: pointer;
    margin-top: 2px;
    margin-left: 1.6rem;
    align-items: center;
  }
  .expanding-button:hover {
    border: solid 1px var(--dark-grey);
  }
  .expanding-button .expand {
    background: url(/icons/icon_dots.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 22px;
    width: 24px;
    height: 24px;
    filter: brightness(0) saturate(100%) invert(48%) sepia(8%) saturate(18%) hue-rotate(46deg) brightness(98%)
      contrast(83%);
  }
  .expanding-button .expand:hover {
    filter: brightness(0);
  }
  .expanding-button .collapse {
    background: url(/icons/icon_chevron.svg) no-repeat;
    transform: rotate(-180deg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    width: 24px;
    height: 24px;
    margin-left: -1px;
    filter: brightness(0) saturate(100%) invert(48%) sepia(8%) saturate(18%) hue-rotate(46deg) brightness(98%)
      contrast(83%);
  }
  .expanding-button .collapse:hover {
    filter: brightness(0);
  }
  .fade-bg {
    background-color: transparent;
    transition: all 2s;
  }
  .chevron {
    background-image: url("/icons/icon_chevron.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    margin-left: -6px;
    /* transform: rotate(-90deg); */
    filter: brightness(0) saturate(100%) invert(48%) sepia(8%) saturate(18%) hue-rotate(46deg) brightness(98%)
      contrast(83%);
  }
  .chevron.open {
    transform: rotate(180deg);
  }
  .keyword.included > .chevron,
  .keyword.excluded > .chevron {
    filter: brightness(1);
  }
  .category-icon {
    filter: brightness(1);
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .delete-keyword {
    background-image: url("/buttons/btn_delete_keyword.svg");
    background-position: center;
    background-repeat: no-repeat;
    color: var(--white);
    cursor: pointer;
    padding-right: 10px;
    width: 32px;
    height: 32px;
    margin-left: -12px;
    align-self: center;
    border-radius: 18px;
  }
  .delete-keyword:hover {
    background-image: url("/buttons/btn_delete_keyword_hover.svg");
  }
  .clear-keywords {
    background-image: url("/buttons/btn_clear_keywords.svg");
    background-repeat: no-repeat;
    background-size: 60px;
    cursor: pointer;
    margin-top: -8px;
    margin-bottom: -8px;
    margin-left: 10px;
    width: 60px;
    height: 60px;
    border-radius: 60px;
  }
  .clear-keywords:hover {
    background-image: url("/buttons/btn_clear_keywords_hover.svg");
  }
  .dropdown {
    max-height: calc(100vh - var(--header-height) * 2);
    overflow-y: auto;
    z-index: 9999;
    padding: 8px 0px;
    position: fixed;
    margin-left: 3px;
    background-color: var(--white-grey);
    font-size: 1rem;
    border: 1px solid var(--solid-grey);
    border-radius: 10px;
  }
  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 8px 32px;
    background-size: 36px;
    background-position: 4px 0px;
    background-repeat: no-repeat;
    cursor: pointer;
    user-select: none;
  }
  .dropdown-item.plus {
    background-image: url("/buttons/btn_plus.svg");
    background-size: 24px;
    background-position: 8px 5px;
  }
  .dropdown-item.minus {
    background-image: url("/buttons/btn_minus.svg");
    background-size: 24px;
    background-position: 8px 5px;
  }
  .dropdown-item img {
    width: 20px;
    height: 20px;
    filter: brightness(0.1);
    margin-right: 12px;
  }
  .dropdown-item.included {
    color: var(--solid-blue);
    background-image: url("/icons/icon_check_include.svg");
    cursor: pointer;
    user-select: none;
  }
  .dropdown-item.excluded {
    color: var(--bright-red);
    background-image: url("/icons/icon_check_exclude.svg");
    cursor: pointer;
    user-select: none;
  }
  .dropdown-item.active.include {
    color: var(--solid-blue);
  }
  .dropdown-item.active.exclude {
    color: var(--bright-red);
  }
  .dropdown-item.active.include img {
    filter: brightness(0) saturate(100%) invert(41%) sepia(89%) saturate(730%) hue-rotate(160deg) brightness(93%)
      contrast(85%);
  }
  .dropdown-item.active.exclude img {
    filter: brightness(0) saturate(100%) invert(23%) sepia(67%) saturate(5462%) hue-rotate(337deg) brightness(95%)
      contrast(95%);
  }
  .dropdown-item:hover {
    background-color: var(--white);
  }
  .separator {
    width: 90%;
    margin: 0.5rem auto;
    border-bottom: solid 1px var(--black);
  }
</style>
